<template>
  <ScrollPanel class="col-12 max-h-screen" style="height: 400px">
    <p>Effective Date: 4th August, 2023</p>
    <p>
      Welcome to SmeSpot. We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard the data we collect from users of our website. By accessing or
      using our website, you agree to the terms of this Privacy Policy.
    </p>
    <h2>1. Collection and Use of Employee Data:</h2>
    <p>We may collect the following personal data from our employees for authentication and authorization purposes:</p>
    <ul>
      <li>Email addresses: We collect email addresses to enable secure login and communication with our employees.</li>
      <li>Names: Employee names are collected to ensure accurate identification and access control.</li>
    </ul>
    <p>We use this information solely for the purpose of authenticating employees and granting appropriate access to our systems and resources. We will not share this data with any third parties unless required by law.</p>
    <h2>2. Collection and Use of Customer Data:</h2>
    <p>For customers using our services, we may collect the following data for the specific purposes outlined below:</p>
    <ul>
      <li>Names: We collect customer names to address them properly and personalize their experience.</li>
      <li>Date of Birth (DOB): Customer DOB is collected for verification purposes, especially in situations where age restrictions apply.</li>
      <li>Mobile Numbers: We collect mobile numbers to contact customers when necessary and provide essential updates about our services.</li>
    </ul>
    <p>The data collected from our customers is exclusively used to generate invoices for their purchases and to create operational reports that help us improve our services. This information is not shared with any external parties, and we use it only for internal purposes.</p>
    <h2>3. Data Sharing:</h2>
    <p>We take your privacy seriously, and we want to assure you that your data will not be shared with anyone under any circumstances, except as described in this Privacy Policy. We will not sell, rent, or lease your personal information to third parties.</p>
    <p>Exceptions to data sharing may occur in the following situations:</p>
    <ul>
      <li>Legal Obligations: We may disclose your data if required by law or in response to valid legal requests, such as subpoenas or court orders.</li>
      <li>Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your data may be transferred to the new entity.</li>
    </ul>
    <h2>4. Data Security:</h2>
    <p>
      We employ industry-standard security measures to protect your data from unauthorized access, alteration, disclosure, or destruction. While we strive to ensure the security of your data, no method of data transmission or storage is 100% secure. We cannot guarantee the
      absolute security of your information.
    </p>
    <h2>5. Data Retention:</h2>
    <p>We will retain your data only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, or as required by applicable laws and regulations.</p>
    <h2>6. Your Rights:</h2>
    <p>
      As a user of our website, you have certain rights regarding your personal information. You have the right to access, correct, and delete your data. If you wish to exercise any of these rights or have any concerns about your data, please contact us using the information
      provided at the end of this Privacy Policy.
    </p>
    <h2>7. Changes to this Privacy Policy:</h2>
    <p>We may update this Privacy Policy from time to time. The latest version will be posted on our website with the effective date. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.</p>
    <h2>8. Contact Us:</h2>
    <p>If you have any questions, comments, or concerns about this Privacy Policy or our data practices, please contact us at: <a href="mailto:manik@smespot.com">manik@smespot.com</a></p>
    <p>By using our website, you agree to the terms of this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please refrain from using our website.</p>
  </ScrollPanel>
</template>

<script>
import ScrollPanel from 'primevue/scrollpanel';
export default {
  name: 'privacy_policy_modal',
  components:{
    ScrollPanel,
  }
};
</script>
