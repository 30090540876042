<template>
  <div class="flex flex-wrap w-full min-h-screen">
    <div class="grid grid-cols-2 w-full">
      <Dialog :visible.sync="showPrivacyPolicy" :containerStyle="{width: '50vw'}" >
        <template #header>
          <h2>Privacy Policy!</h2>
        </template>
        <PrivacyPolicy />
        <template #footer>
          <Button label="Close" icon="pi pi-check" autofocus @click.prevent="()=>(showPrivacyPolicy=false)" />
        </template>
      </Dialog>
      <div class="flex flex-col items-center justify-center gap-2 w-full">
        <div class="flex flex-col gap-2" style="width: 60%">
          <h3 class="text-2xl text-center mt-4">Tell us about you</h3>
          <div class="flex flex-row gap-2 items-center justify-center">
            <InputText class="w-full" type="text" name="firstName" v-model="firstName" placeholder="First Name" />
            <InputText class="w-full" type="text" name="lastName" v-model="lastName" placeholder="Last Name" />
          </div>
          <div class="field">
            <AutoComplete class="p-fluid w-full" v-model="country" :suggestions="filtered" placeholder="Country" @complete="searchCountry($event)" />
          </div>
          <InputText class="w-full" type="text" name="email" v-model="email" placeholder="Email Address" />
          <div class="flex flex-row gap-2 items-center justify-center">
            <InputText class="w-full" type="password" name="password" v-model="password" placeholder="Password" />
            <InputText class="w-full" type="password" name="passwordConfirm" v-model="passwordConfirm" placeholder="Password Confirm" />
          </div>
          <div class="p-field-checkbox text-sm">
            <Checkbox id="terms" v-model="consent" :binary="true" />
            <label for="terms" class="text-gray-900 dark:text-gray-300">
              I agree to SMESPOT's <span class="text-blue-500 cursor-pointer" @click="$emit('tos')">terms of service</span> and
              <span @click="() => (showPrivacyPolicy = true)" class="text-blue-500 cursor-pointer">privacy policy</span>
            </label>
          </div>
          <Button label="Create Account" class="w-full p-button" :disabled="!consent" @click.prevent="createAccount" />
          <Divider />
          <Button class="p-button p-button-link" label="Back" @click.prevent="$router.push({ name: 'sign_up_page' })" />
        </div>
      </div>
      <div class="flex flex-col items-center justify-center border-l border-black">
        <img alt="sign-up" src="@/assets/images/signup-2.jpg" style="width: 80%" />
      </div>
    </div>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import countries from '@/plugins/country-regions';
import AutoComplete from 'primevue/autocomplete';
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';
import Divider from 'primevue/divider';
import consultant_service from '@/services/consultant_service';
import client_service from '@/services/client_service';
import PrivacyPolicy from '@/views/popups/privacy-policy.vue';
import Dialog from 'primevue/dialog';

export default {
  components: {
    Divider,
    InputText,
    AutoComplete,
    Checkbox,
    Button,
    Dialog,
    PrivacyPolicy,
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      password: '',
      passwordConfirm: '',
      email: '',
      country: null,
      consent: false,
      consult: this.$route.params.type === 'consultant',
      countries: countries,
      filtered: [],
      showPrivacyPolicy: false,
    };
  },
  methods: {
    searchCountry(event) {
      if (!event.query.trim().length) {
        this.filtered = [...this.countries];
      } else {
        this.filtered = this.countries
          .filter((c) => {
            return c.name.toLowerCase().startsWith(event.query.toLowerCase());
          })
          .map((c) => c.name);
      }
    },
    createAccount() {
      if (this.consult) {
        consultant_service
          .signupConsultant({
            name: `${this.firstName} ${this.lastName}`,
            email: this.email,
            password: this.password,
            country: this.country,
          })
          .then(() => {
            this.$msg.success({ title: 'Welcome Onboard 👋', message: 'consultant signed up successfully' });
            this.$router.push({ name: 'sign_up_complete_page', params: { email: this.email } });
          })
          .catch(({ error }) => this.$msg.error({ message: error, title: 'Unable to sign up' }));
      } else {
        client_service
          .signupClient({
            name: `${this.firstName} ${this.lastName}`,
            email: this.email,
            password: this.password,
            country: this.country,
          })
          .then(() => {
            this.$msg.success({ title: 'Welcome Onboard 👋', message: 'user signed up successfully' });
            this.$router.push({ name: 'sign_up_complete_page', params: { email: this.email } });
          })
          .catch(({ error }) => this.$msg.error({ message: error, title: 'Unable to sign up' }));
      }
    },
  },
};
</script>
